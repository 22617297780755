/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get'; // Removed smartCase with sidekick disabling
import { useComponentLookupContext } from '../../ComponentLookupContext';

export const ContentModulePropTypes = {
  contentTypeId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.object.isRequired,
  wrapSection: PropTypes.bool,
  pageLandingSlug: PropTypes.string
};

function ContentModule({ wrapSection, contentTypeId, fields, pageLandingSlug }) {
  const componentLookup = useComponentLookupContext();

  const Main = componentLookup(contentTypeId);

  if (!Main) {
    // eslint-disable-next-line no-console
    console.log(
      `Did not find mapping for Content Type ${contentTypeId}. Please add an override to the build property in .lastrevrc`
    );
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  if (!wrapSection) return <Main {...fields} />;

  const defaultThemeName = 'default';
  let theme = get(fields, 'theme.theme', defaultThemeName);

  switch (fields._contentTypeId) {
    case 'moduleFullWidthContainer':
      if (get(fields, 'cta.as', '').startsWith('/virtual-training')) theme = 'virtual-training';
      break;
    case 'moduleTopics':
      if (pageLandingSlug !== 'home') theme = 'topics';
      break;
    case 'moduleMultiColumnVideos':
      theme = 'videos';
      break;
    default:
      break;
  }

  const themeClassName = `theme-${theme}`;

  return <Main {...fields} themeClassName={themeClassName} className={`section ${fields._contentTypeId}`} />;
}

ContentModule.propTypes = ContentModulePropTypes;

ContentModule.defaultProps = {
  wrapSection: false,
  pageLandingSlug: ''
};

export default ContentModule;
