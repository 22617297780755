import React from 'react';
import PropTypes from 'prop-types';
import CopyShare from '../CopyShare';
import styles from './SocialLinks.module.scss';

export const SocialLinksPropTypes = {
  url: PropTypes.string.isRequired
};

function SocialLinks({ url }) {
  return (
    <ul className={styles.socials} data-testid="SocialLinks">
      <li>
        <a
          className={styles.facebook}
          href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
          data-testid="SocialLinks-facebook">
          <span className="sr-only">Facebook</span>
        </a>
      </li>
      <li>
        <a className={styles.twitter} href={`http://www.twitter.com/share?url=${url}`}>
          <span className="sr-only">Twitter</span>
        </a>
      </li>
      <li>
        <a className={styles.linkedin} href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}>
          <span className="sr-only">Linkedin</span>
        </a>
      </li>
      <li>
        <CopyShare url={url} />
      </li>
    </ul>
  );
}

SocialLinks.propTypes = SocialLinksPropTypes;

export default SocialLinks;
