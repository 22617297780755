/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { useSelect } from 'downshift';
import cx from 'clsx';
import { connect } from 'react-redux';
import { selectTime } from '../../redux/modules/webinars';
import styles from './TimeSelectionDropdown.module.scss';
import { get24HourTime } from '../../utils/reactCalendarFunctions';

const mapDispatchToProps = {
  timeSelectionHandler: selectTime
};

const mapStateToProps = (state) => {
  const {
    webinars: {
      current: { availableTimes, selectedTime, selectedLanguage }
    }
  } = state;
  return {
    availableTimes,
    selectedTime,
    selectedLanguage
  };
};

const TimeSelectionDropdown = ({ availableTimes, selectedTime, timeSelectionHandler, selectedLanguage }) => {
  const { t, lang } = useTranslation();
  const handleTimeSelection = ({ selectedItem }) => {
    if (!selectedItem) return;
    timeSelectionHandler({ selectedTime: selectedItem });
  };

  const { isOpen, selectedItem, getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    items: availableTimes,
    selectedItem: selectedTime,
    onSelectedItemChange: handleTimeSelection
  });

  return (
    <div data-testid="TimeSelectionDropdown" className={styles.dropdown}>
      <label className={styles.label} htmlFor="time" id="downshift-0-label">
        {t('common:formInputLabelSelectTime')}
      </label>

      <button
        type="button"
        {...getToggleButtonProps()}
        className={cx('custom-select', styles.select)}
        title={t('common:formInputLabelSelectTime')}>
        {selectedItem || t('common:textDropdownDefault')}
      </button>

      <ul {...getMenuProps()} className={cx('select-options', { 'menu-open': isOpen })}>
        {isOpen && availableTimes && availableTimes.length
          ? availableTimes.map((time, index) => {
              const formattedTime = get24HourTime(time, selectedLanguage || lang);
              return (
                <li id="time" key={time} {...getItemProps({ time, index })}>
                  {formattedTime}
                </li>
              );
            })
          : null}
      </ul>
    </div>
  );
};

TimeSelectionDropdown.propTypes = {
  availableTimes: PropTypes.arrayOf(PropTypes.string),
  selectedTime: PropTypes.string,
  timeSelectionHandler: PropTypes.func,
  selectedLanguage: PropTypes.string
};

TimeSelectionDropdown.defaultProps = {
  availableTimes: null,
  selectedTime: null,
  timeSelectionHandler: null,
  selectedLanguage: null
};

export default connect(mapStateToProps, mapDispatchToProps)(TimeSelectionDropdown);
