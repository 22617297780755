import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import styles from './GlobalFooter.module.scss';
import GlobalLanguageSelection from '../GlobalLanguageSelection';
import ElementLink, { ElementLinkPropTypes } from '../ElementLink';

const GlobalFooterColumnPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  title: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)).isRequired
};

const GlobalFooterPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape(GlobalFooterColumnPropTypes)).isRequired
};

const GlobalFooterColumn = ({ _id, _contentTypeId, title, links }) => {
  if (!title || !links || !links.length) return null;

  return (
    <li
      className={styles.menuColumn}
      {...sidekick(_id, null, _contentTypeId, 'Footer Column')}
      data-testid="GlobalFooter-Column">
      <div
        className={styles.menuColumnHeader}
        {...sidekick(_id, 'title', null, 'Footer Column Title')}
        data-testid="GlobalFooter-ColumnHeader">
        {title}
      </div>
      <ul className={styles.menuColumnList} {...sidekick(_id, 'links', null, 'Footer Column Links')}>
        {links.map((link) => (
          <li className={styles.menuColumnListItem} key={`secondary-nav-link-${link._id || link.as || link.href}`}>
            <ElementLink
              href={link.href}
              as={link.as}
              trackingId={link.trackingId}
              style={link.style}
              className={styles.columnListItemLink}
              linkText={link.linkText}
              isModal={link.isModal}
              download={link.download}>
              {link.linkText}
            </ElementLink>
          </li>
        ))}
      </ul>
    </li>
  );
};

GlobalFooterColumn.propTypes = GlobalFooterColumnPropTypes;

function GlobalFooter({ _id, /* _contentTypeId, */ columns }) {
  return (
    <footer id="GlobalFooter" data-testid="GlobalFooter" className={styles.footer} {...sidekick(_id, null, null, 'Footer Navigation')}>
      <div className={styles.footerContainer}>
        <div className={styles.menuWrap}>
          <ul
            data-testid="GlobalFooter-column-list"
            className={`${styles.menu}`}
            role="navigation"
            aria-label="Global"
            {...sidekick(_id, 'columns', null, 'Footer Columns')}>
            {columns && columns.length
              ? columns.map((column) => (
                  <GlobalFooterColumn
                    _id={column._id}
                    _contentTypeId={column._contentTypeId}
                    title={column.internalTitle}
                    links={column.links}
                    key={column._id}
                  />
                ))
              : null}
          </ul>
        </div>

        <hr className={styles.hrSplit} />

        <div className={styles.globalLanguageSelectionWrap}>
          <GlobalLanguageSelection />
        </div>
      </div>
    </footer>
  );
}

GlobalFooter.propTypes = GlobalFooterPropTypes;

GlobalFooter.defaultProps = {};
GlobalFooterColumn.defaultProps = {
  title: null
};

export default GlobalFooter;
