import ModuleHero from '../components/ModuleHero';
import PageCourse from '../components/PageCourse';
import ModuleTopics from '../components/ModuleTopics';
import Layout from '../components/Layout';
import PageLiveCourse from '../components/PageLiveCourse';
import ModuleFeaturedCourse from '../components/ModuleFeaturedCourse';
import ModuleMultiColumnCourses from '../components/ModuleMultiColumnCourses';
import LayoutSection from '../components/LayoutSection';
import ModuleCard from '../components/ModuleCard';
import Categories from '../components/Categories';
import PageCourseTopic from '../components/PageCourseTopic';
import ModuleRichText from '../components/ModuleRichText';
import ModuleTextList from '../components/ModuleTextList';
import ModuleMultiColumnCards from '../components/ModuleMultiColumnCards';
import ModuleCarousel from '../components/ModuleCarousel';
import ModuleMultiColumnVideos from '../components/ModuleMultiColumnVideos';
import ModuleTextMedia from '../components/ModuleTextMedia';
import ModuleFullWidthContainer from '../components/ModuleFullWidthContainer';
import ModuleFeaturedContent from '../components/ModuleFeaturedContent';
import ElementVideo from '../components/ElementVideo';
import ElementLink from '../components/ElementLink';
import SocialLinks from '../components/SocialLinks';

export default {
  moduleHero: ModuleHero,
  pageCourse: PageCourse,
  moduleTopics: ModuleTopics,
  settingsGlobal: Layout,
  pageLiveCourse: PageLiveCourse,
  moduleFeaturedCourse: ModuleFeaturedCourse,
  moduleMultiColumnCourses: ModuleMultiColumnCourses,
  layoutSection: LayoutSection,
  moduleCard: ModuleCard,
  categories: Categories,
  pageCourseTopic: PageCourseTopic,
  moduleRichText: ModuleRichText,
  moduleTextList: ModuleTextList,
  moduleMultiColumnCards: ModuleMultiColumnCards,
  moduleCarousel: ModuleCarousel,
  moduleMultiColumnVideos: ModuleMultiColumnVideos,
  moduleTextMedia: ModuleTextMedia,
  moduleFullWidthContainer: ModuleFullWidthContainer,
  moduleFeaturedContent: ModuleFeaturedContent,
  elementVideo: ElementVideo,
  elementLink: ElementLink,
  socialLinks: SocialLinks
};
