/* eslint-disable react/prop-types */
import React, { createContext, useContext } from 'react';

const CourseTopicContext = createContext(() => {});

export const CourseTopicContextProvider = ({ children, value }) => {
  function getTopicPath(topicslug) {
    const matchingEntry = value.find((entry) => entry.params.topicslug === topicslug);

    if (matchingEntry) {
      return `/${matchingEntry.params.slug}/${matchingEntry.params.topicslug}`;
    }
    return null;
  }

  return <CourseTopicContext.Provider value={{ getTopicPath }}>{children}</CourseTopicContext.Provider>;
};

export const useCourseTopicContext = () => {
  const context = useContext(CourseTopicContext);
  if (!context) {
    throw new Error('useCourseTopicContext must be used within a CourseTopicProvider');
  }
  return context;
};

export default CourseTopicContext;
