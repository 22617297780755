import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import cx from 'clsx';
import PropTypes from 'prop-types';
import styles from './ModuleMultiColumnCards.module.scss';
import ModuleCard, { ModuleCardPropTypes } from '../ModuleCard';

export const ModuleMultiColumnCardsPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(ModuleCardPropTypes)).isRequired
};

function ModuleMultiColumnCards({ _id /* , _contentTypeId */, title, subtitle, cards }) {
  return (
    <div data-testid="ModuleMultiColumnCards" className={styles.moduleContainerWrap}>
      {title || subtitle ? (
        <div className={styles.sectionHeaderWrap} data-testid="ModuleMultiColumnCards-header">
          {title ? (
            <div className={styles.sectionTitleWrap} {...sidekick(_id, 'title')}>
              <h3 className={styles.sectionTitle} data-testid="ModuleMultiColumnCards-title">
                {title}
              </h3>
            </div>
          ) : null}

          {subtitle ? (
            <div className={styles.subtitleWrap} {...sidekick(_id, 'subtitle')}>
              <h5 className={styles.subtitle} data-testid="ModuleMultiColumnCards-subtitle">
                {subtitle}
              </h5>
            </div>
          ) : null}
        </div>
      ) : null}

      {cards && cards.length ? (
        <div
          data-testid="ModuleMultiColumnCards-CardsWrap"
          className={styles.moduleContainerRow}
          {...sidekick(_id, 'cards')}>
          {cards.map((card) => (
            <div
              className={cx(styles.cardCol, cards.length > 2 && cards.length % 2 !== 0 && styles.odd)}
              key={`${card._id}-col`}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <ModuleCard {...card} className={styles.card} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
ModuleMultiColumnCards.propTypes = ModuleMultiColumnCardsPropTypes;

ModuleMultiColumnCards.defaultProps = {
  title: null,
  subtitle: null
};

export default ModuleMultiColumnCards;
