import React, { useEffect, useState } from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import keys from 'lodash/keys';
import find from 'lodash/find';
import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
import intersection from 'lodash/intersection';
import map from 'lodash/map';
import useTranslation from 'next-translate/useTranslation';
import { connect } from 'react-redux';
import cx from 'clsx';
import styles from './ModuleFeaturedCourse.module.scss';
import PageCoursePropTypes from '../PageCourse/PageCoursePropTypes';
import Asset from '../Asset';
import ElementLink from '../ElementLink';
import TopicLink from '../TopicLink';
import useCourseImage from '../../utils/useCourseImage';
import useTopicDetails from '../../utils/useTopicDetails';

const mapStateToProps = ({ courses }) => ({
  completedCourseTopics: courses.completedCourseTopics,
  completedCourses: courses.completed,
  completedCoursesLoaded: courses.loaded,
  completedCourseTopicsLoaded: courses.loadedCourseTopics
});

export const ModuleFeaturedCoursePropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  // _href: PropTypes.string.isRequired,
  // _as: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  course: PropTypes.shape(PageCoursePropTypes).isRequired
};

function ModuleFeaturedCourse({
  _id,
  /* _contentTypeId,
  _href,
  _as, */
  title,
  course,
  className,
  themeClassName,
  // redux state
  completedCourseTopics,
  completedCourses,
  completedCoursesLoaded,
  completedCourseTopicsLoaded
}) {
  const { _id: courseId, topics } = course;

  const [percentCompleted, setPercentCompleted] = useState(0);
  const [showCta, setShowCta] = useState(true);
  const [courseStartCtaTextKey, setCourseStartCtaTextKey] = useState('common:textStartCourse');
  const [nextTopic, setNextTopic] = useState(null);
  const [courseTopicsCalculationsCompleted, setCourseTopicsCalculationsCompleted] = useState(false);
  const [coursesCalculationsCompleted, setCoursesCalculationsCompleted] = useState(false);

  useEffect(() => {
    if (!courseTopicsCalculationsCompleted && topics?.length && completedCourseTopicsLoaded) {
      const courseTopicIds = map(topics, '_id') || [];
      const completedTopicIds = intersection(keys(get(completedCourseTopics, courseId, {})), courseTopicIds);
      const completedLength = completedTopicIds.length;
      const percent = Math.round(100 * (completedLength / topics.length));
      setPercentCompleted(percent);
      const topic = find(topics, ({ _id: topicId }) => !includes(completedTopicIds, topicId));
      if (topic) {
        setNextTopic(topic);
      }
      if (percent > 0) {
        setCourseStartCtaTextKey('common:textResumeCourse');
      }
      setCourseTopicsCalculationsCompleted(true);
    }
  }, [courseId, topics, courseTopicsCalculationsCompleted, completedCourseTopicsLoaded, completedCourseTopics]);

  useEffect(() => {
    if (!coursesCalculationsCompleted && completedCoursesLoaded) {
      if (has(completedCourses, courseId)) {
        setShowCta(false);
      }
      setCoursesCalculationsCompleted(true);
    }
  }, [courseId, coursesCalculationsCompleted, completedCoursesLoaded, completedCourses]);

  const { t } = useTranslation();

  const { courseImage, assetParentContentTypeId, assetParentFieldName } = useCourseImage(
    _id,
    course.featuredThumbnail || course.thumbnail || course.image,
    course.category
  );

  const topicString = useTopicDetails(course.topics);

  const linkText = t(courseStartCtaTextKey);

  return (
    <section
      data-testid="ModuleFeaturedCourse"
      className={cx(className, themeClassName, styles[themeClassName], styles.moduleFeaturedCourseWrap)}
      {...sidekick(_id, null, null, title || 'Module - Featured Course')}>
      <div className={styles.moduleContainerWrap}>
        {title ? (
          <div className={styles.sectionHeaderWrap}>
            <div className={styles.sectionTitleWrap} {...sidekick(_id, 'title', null, 'Title')}>
              <h3 className={styles.sectionTitle} data-testid="ModuleFeaturedCourse-title">
                {title}
              </h3>
            </div>
          </div>
        ) : null}

        <div className={styles.moduleContainerRow}>
          {courseImage ? (
            <div
              className={styles.courseImageCol}
              data-testid="ModuleFeaturedCourse-image"
              {...sidekick(_id, 'courseImage', null, 'Course Image')}>
              <Asset
                _id={assetParentContentTypeId}
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...courseImage}
                fieldName={assetParentFieldName}
                imageWrapClassName="imageCircle"
              />
            </div>
          ) : null}

          <div className={styles.courseDetailsCol}>
            {course.name ? (
              <div className={styles.courseNameWrap}>
                <h3
                  data-testid="ModuleFeaturedCourse-courseName"
                  className={styles.courseName}
                  {...sidekick(course._id, 'name', null, 'Course Name')}>
                  {course.name}
                </h3>
              </div>
            ) : null}

            {course?.topics?.length ? (
              <div
                className={styles.topicsWrap}
                data-testid="ModuleFeaturedCourse-TopicDetailsWrap"
                {...sidekick(course._id, 'topics', null, 'Course Topics')}>
                {topicString}
              </div>
            ) : null}

            {showCta ? (
              <div className={styles.courseCtaWrap} data-testid="ModuleFeaturedCourse-ctaWrap">
                {nextTopic ? (
                  <TopicLink
                    topic={nextTopic}
                    courseHref={course._href}
                    courseAs={course._as}
                    linkText={linkText}
                    className={styles.courseCta}
                  />
                ) : (
                  <ElementLink
                    className={styles.courseCta}
                    href={course._href}
                    as={course._as}
                    linkText={linkText}
                    isModal={false}
                    download={false}
                  />
                )}
              </div>
            ) : null}
          </div>

          <div className={styles.courseProgressCol} data-testid="ModuleFeaturedCourse-footer">
            {percentCompleted > 0 ? (
              <div className={styles.courseProgressWrap} data-testid="ModuleFeaturedCourse-ProgressWrap">
                <div className={styles.courseProgressHeaderWrap}>
                  <h6 className={styles.courseProgressHeader} data-testid="ModuleFeaturedCourse-courseProgress">
                    {t(`common:textCourseProgress`)}
                  </h6>
                </div>

                <div className={styles.courseProgressBarWrap}>
                  <div className={styles.progress}>
                    <div
                      data-testid="ModuleFeaturedCourse-progressBar"
                      className={styles.progressBar}
                      style={{ width: `${percentCompleted}%` }}
                      role="progressbar"
                      aria-valuenow={percentCompleted}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                </div>
              </div>
            ) : null}

            {course.objectives ? (
              <div
                className={styles.learningObjectivesWrap}
                {...sidekick(course._id, 'objectives', null, 'Course Objectives')}>
                <p data-testid="ModuleFeaturedCourse-courseObjectives">
                  <span
                    className={styles.learningObjectivesHeader}
                    data-testid="ModuleFeaturedCourse-courseObjectivesHeader">
                    {t(`common:textLearningObjectives`)}:
                  </span>{' '}
                  {course.objectives}
                </p>
              </div>
            ) : null}

            {showCta ? (
              <div className={styles.courseCtaWrap} data-testid="ModuleFeaturedCourse-ctaWrap-mobile">
                {nextTopic ? (
                  <TopicLink
                    topic={nextTopic}
                    courseHref={course._href}
                    courseAs={course._as}
                    linkText={linkText}
                    className={styles.courseCta}
                  />
                ) : (
                  <ElementLink
                    className={styles.courseCta}
                    href={course._href}
                    as={course._as}
                    linkText={linkText}
                    isModal={false}
                    download={false}
                  />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}
ModuleFeaturedCourse.propTypes = {
  ...ModuleFeaturedCoursePropTypes,
  isLoggedIn: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  completedTopicsObject: PropTypes.object,
  className: PropTypes.string,
  themeClassName: PropTypes.string
};

ModuleFeaturedCourse.defaultProps = {
  isLoggedIn: false,
  completedTopicsObject: null,
  className: '',
  themeClassName: 'theme-default'
};

export default React.memo(connect(mapStateToProps)(ModuleFeaturedCourse));
