import React, { useEffect, useState } from 'react';
import find from 'lodash/find';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import useTranslation from 'next-translate/useTranslation';
import { connect } from 'react-redux';
import cx from 'clsx';
import styles from './PageLiveCourse.module.scss';
import useCourseImage from '../../utils/useCourseImage';
import hashCode from '../../utils/hashCode';
import RichTextParser from '../RichTextParser';
import minutesToHours from '../../utils/minutesToHours';
import getThemeClass from '../../utils/getThemeClass';

import { setCurrentEventRegistration, register } from '../../redux/modules/webinars';
import PageLiveCoursePropTypes from './PageLiveCoursePropTypes';
import Asset from '../Asset';
import LanguageSelectionDropdown from '../LanguageSelectionDropdown';
import Calendar from '../Calendar';
import TimeSelectionDropdown from '../TimeSelectionDropdown';

const mapStateToProps = (state) => {
  const {
    webinars: {
      current: { completedRegistration, completingRegistration, selectedOccurrenceId, selectedWebinarId },
      eventRegistrations,
      error,
      webinarFull
    },
    user: { id }
  } = state;
  return {
    completedRegistration,
    completingRegistration,
    selectedOccurrenceId,
    selectedWebinarId,
    eventRegistrations,
    loggedIn: !!id,
    error,
    webinarFull
  };
};

const mapDispatchToProps = {
  setCurrent: setCurrentEventRegistration,
  submitRegistration: register
};

export const CourseHighlights = ({ courseHighlights }) => {
  if (!courseHighlights || !courseHighlights.length) return null;
  return (
    <ul className={styles.courseHighlightsList} data-testid="LiveCourseDetails-courseHighlights">
      {courseHighlights.map((highlight) => (
        <li key={hashCode(highlight)} data-testid="LiveCourseDetails-courseHighlightsItem">
          {highlight}
        </li>
      ))}
    </ul>
  );
};

CourseHighlights.propTypes = {
  courseHighlights: PropTypes.arrayOf(PropTypes.string)
};

CourseHighlights.defaultProps = {
  courseHighlights: []
};

const DurationPropTypes = {
  duration: PropTypes.number.isRequired
};

export const Duration = ({ duration }) => {
  const { t } = useTranslation();
  const durationObj = minutesToHours(duration);

  const durationArr = [];
  if (durationObj.hours && durationObj.hoursLookupShort) {
    const hoursText = t(`common:${durationObj.hoursLookupShort}`);
    durationArr.push(`${durationObj.hours} ${hoursText}`);
  }

  if (durationObj.minutes && durationObj.minutesLookupShort) {
    const minsText = t(`common:${durationObj.minutesLookupShort}`);
    durationArr.push(`${durationObj.minutes} ${minsText}`);
  }

  return <>{durationArr.join(' ')}</>;
};

Duration.propTypes = DurationPropTypes;

function PageLiveCourse({
  _id,
  // _as,
  // _href,
  _contentTypeId,
  name,
  slug,
  category,
  description,
  image,
  thumbnail,
  courseHighlights,
  duration,
  languageZoomIDs,
  completingRegistration,
  completedRegistration,
  eventRegistrations,
  loggedIn,
  error,
  webinarFull,
  reschedulingWebinarId,
  reschedulingOccurrenceId,
  loginPromptHeader,
  loginPromptCopy,
  selectedOccurrenceId,
  selectedWebinarId,
  setCurrent,
  submitRegistration
}) {
  const { t } = useTranslation();

  const [url, setUrl] = useState('');
  const [registrationButtonEnabled, setRegistrationButtonEnabled] = useState(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    setUrl(window.location.href);
  }, [url]);

  useEffect(() => {
    setCurrent({
      reschedulingWebinarId,
      reschedulingOccurrenceId
    });
    return () => {
      setCurrent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reschedulingWebinarId, reschedulingOccurrenceId]);

  useEffect(() => {
    let registered = false;
    if (selectedOccurrenceId && selectedWebinarId) {
      const found = find(
        eventRegistrations,
        (e) => e.webinarId === selectedWebinarId && e.occurrenceId === selectedOccurrenceId
      );
      registered = !!found;
    }
    setAlreadyRegistered(registered);
    setRegistrationButtonEnabled(!completingRegistration && selectedOccurrenceId && !registered);
  }, [completingRegistration, selectedOccurrenceId, selectedWebinarId, eventRegistrations]);

  const { courseImage, assetParentContentTypeId, assetParentFieldName } = useCourseImage(
    _id,
    thumbnail || image,
    category
  );

  return (
    <div
      className={`${styles.pageLiveCourse}`}
      {...sidekick(_id, null, null, 'Page - Live Course')}
      data-testid="LiveCourseDetails">
      <div className={styles.moduleContainerWrap}>
        <div className={styles.moduleContainerRow}>
          <div
            className={cx(styles.detailsCol, 'themeBgColor', `theme-${getThemeClass({ _contentTypeId, category })}`)}>
            <div className={styles.detailsContent}>
              {courseImage ? (
                <div className={styles.imageWrap} data-testid="LiveCourseDetails-courseImageWrap">
                  <Asset
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...courseImage}
                    _id={assetParentContentTypeId}
                    fieldName={assetParentFieldName}
                    imageWrapClassName="imageCircle"
                  />
                </div>
              ) : null}

              <div className={styles.detailsInnerContent}>
                {name ? (
                  <div className={styles.nameWrap} {...sidekick(_id, 'name')}>
                    <h1 className={`${styles.name}`} data-testid="PageLiveCourse-name">
                      {name}
                    </h1>
                  </div>
                ) : null}

                {description ? (
                  <div className={styles.descriptionWrap} {...sidekick(_id, 'description')}>
                    <h2 className={styles.descriptionHeader} data-testid="PageLiveCourse-descriptionHeader">
                      {t('common:headerEventDescription')}
                    </h2>
                    <p className={styles.description} data-testid="PageLiveCourse-description">
                      {description}
                    </p>
                  </div>
                ) : null}

                {duration ? (
                  <div className={styles.durationWrap} {...sidekick(_id, 'duration')}>
                    <h3 className={styles.durationHeader} data-testid="LiveCourseDetails-durationHeader">
                      {t('common:headerDuration')}
                    </h3>
                    <p {...sidekick(_id, 'duration')} data-testid="LiveCourseDetails-duration">
                      <Duration duration={duration} />
                    </p>
                  </div>
                ) : null}

                {courseHighlights ? (
                  <div className={styles.highlightsWrap} {...sidekick(_id, 'courseHighlights')}>
                    <h3 className={styles.highlightsHeader} data-testid="PageLiveCourse-courseHighlightsHeader">
                      {t('common:headerLiveCourseHighlightsHeader')}
                    </h3>
                    <CourseHighlights id={_id} courseHighlights={courseHighlights} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className={`${styles.registerCol} theme-${getThemeClass({ _contentTypeId, category })}`}>
            <div data-testid="LiveCourseDetails-rightcontent" className={styles.registerContent}>
              {error ? (
                <div className="LiveCourse_error" data-testid="LiveCourseDetails-error">
                  {error}
                </div>
              ) : null}
              {loggedIn ? (
                <>
                  {completedRegistration ? (
                    <div className="text-left pt-4">
                      <h2 data-testid="LiveCourseDetails-registrationCompleted">
                        {t('common:textRegistrationCompleted')}
                      </h2>
                      <p data-testid="LiveCourseDetails-emailConfirmationWillBeSent">
                        {t('common:textEmailConfirmationWillBeSent')}
                      </p>
                    </div>
                  ) : (
                    <>
                      <div
                        className={styles.languageSelectionWrap}
                        data-testid="LiveCourseDetails-LanguageSelectionDropdownWrap">
                        <LanguageSelectionDropdown
                          slug={slug}
                          contentId={_id}
                          languageZoomIDs={languageZoomIDs}
                          data-testid="LiveCourseDetails-LanguageSelectionDropdown"
                        />
                      </div>

                      <div className={styles.calendarWrap}>
                        <Calendar />
                      </div>

                      <div className={styles.timeSelectionWrap}>
                        <TimeSelectionDropdown />
                      </div>

                      {alreadyRegistered ? (
                        <div className={styles.alreadyRegistered}>{t('common:textAlreadyRegistered')}</div>
                      ) : null}

                      {webinarFull ? (
                        <div className={styles.alreadyRegistered}>{t('common:textWebinarFull')}</div>
                      ) : null}

                      <div className={styles.btnSubmitWrap}>
                        <button
                          data-testid="WebinarAttendeeForm-submit"
                          className={styles.btnSubmit}
                          onClick={submitRegistration}
                          type="submit"
                          disabled={!registrationButtonEnabled}>
                          {t('common:ctaTextRegister')}
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div className="text-left pt-5 mt-5">
                  {loginPromptHeader ? (
                    <div className={styles.loginHeaderTextWrap} {...sidekick(_id, 'loginPromptHeader')}>
                      <h6 className={styles.loginHeaderText}>{loginPromptHeader}</h6>
                    </div>
                  ) : null}

                  {loginPromptCopy ? (
                    <div className={styles.loginPromptWrap} {...sidekick(_id, 'loginPromptCopy')}>
                      <RichTextParser document={loginPromptCopy} />
                    </div>
                  ) : null}

                  <div className={styles.loginCtaWrap}>
                    <a
                      className={styles.btnSubmit}
                      data-testid="PageLiveCourse-signin-button"
                      href={`https://www.dropbox.com/login?cont=${url}`}>
                      {t('common:ctaTextSignIn')}
                    </a>
                  </div>

                  <div className={styles.createAccountCtaWrap}>
                    <a
                      className={styles.createAccountCta}
                      data-testid="PageLiveCourse-create-account-button"
                      href={`https://www.dropbox.com/register?cont=${url}`}>
                      {t('common:ctaCreateAccount')}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
PageLiveCourse.propTypes = PageLiveCoursePropTypes;

PageLiveCourse.defaultProps = {
  courseHighlights: null,
  selectedLanguage: null,
  selectedOccurrenceId: null,
  jsonLdSchema: {},
  completedRegistration: null,
  completingRegistration: null,
  loggedIn: null,
  error: null,
  webinarFull: null,
  setCurrent: null,
  submitRegistration: null
};

export default connect(mapStateToProps, mapDispatchToProps)(PageLiveCourse);
