import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './CopyShare.module.scss';

export const CopySharePropTypes = {
  url: PropTypes.string.isRequired
};

const CopyShare = ({ url }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    setTimeout(() => setCopySuccess(''), 3000);
  }, [copySuccess]);

  function copyToClipboard(e) {
    e.preventDefault();
    const tempInput = document.createElement('input');
    tempInput.value = 'https://learn.dropbox.com/';
    document.body.appendChild(tempInput);
    tempInput.select();
    navigator.clipboard.writeText(url);
    document.body.removeChild(tempInput);
    setCopySuccess(true);
  }

  return (
    <a
      href={url}
      className={cx(styles.share, copySuccess && styles.copied)}
      onClick={(e) => copyToClipboard(e)}
      data-testid="CopyShare">
      <span className="sr-only">Share</span>
      <div className={cx(styles.alert, copySuccess && styles.show)}>Link copied to clipboard</div>
    </a>
  );
};

CopyShare.propTypes = CopySharePropTypes;

export default CopyShare;
