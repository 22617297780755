/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-onchange */
import React from 'react';
import router, { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useSelect } from 'downshift';
import PropTypes from 'prop-types';

import styles from './GlobalLanguageSelection.module.scss';
import { getLanguageDisplayName } from '../../utils/i18n';

import { locales } from '../../../i18n';

const GlobalLanguageSelection = ({ isPreview }) => {
  const nextRouter = useRouter();
  const pathname = nextRouter ? nextRouter.pathname : '/';
  const asPathName = nextRouter ? nextRouter.asPath : '/';
  const { t, lang } = useTranslation();

  const regex = new RegExp(`(^/${lang})`, 'g');
  const strippedPath = pathname.replace(regex, '');
  const path = strippedPath === '' ? '/' : strippedPath;

  const strippedAsPath = asPathName.replace(regex, '');
  const asPath = strippedAsPath === '' ? '/' : strippedAsPath;

  const handleSelectedLanguageChange = ({ selectedItem: language }) => {
    if (!language || language === lang) return;
    if (isPreview) {
      router.push(asPath, undefined, { locale: language });
    } else {
      router.push(path, asPath, { locale: language });
    }
  };

  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps, selectedItem, initialSelectedItem } = useSelect({
    items: locales,
    onSelectedItemChange: handleSelectedLanguageChange,
    id: 'GlobalLanguageSelect',
    selectedItem: lang || 'en-US',
    initialSelectedItem: lang || 'en-US',
    defaultSelectedItem: lang || 'en-US',
    initialHighlightedIndex: 0,
    className: styles.globalLanguageDropdown
  });

  return (
    <div data-testid="GlobalLanguageSelection" id="GlobalLanguageSelect-label">
      <button
        type="button"
        data-uxa-log="GlobalLanguageSelection-Button"
        {...getToggleButtonProps()}
        className={styles.globalLanguageDropdownToggle}
        title={t('common:formInputLabelGlobalSelectLanguage')}>
        {getLanguageDisplayName(selectedItem, t) ||
          getLanguageDisplayName(initialSelectedItem, t) ||
          t('common:formInputLabelGlobalSelectLanguage')}
      </button>

      <ul {...getMenuProps()} className={styles.globalLanguageDropdown}>
        {isOpen &&
          locales.map((language, index) => (
            <li
              data-testid="GlobalLanguageSelection-select"
              key={`${language}`}
              {...getItemProps({ language, index })}
              className={styles.globalLanguageDropdownItem}>
              {getLanguageDisplayName(language, t)}
            </li>
          ))}
      </ul>
    </div>
  );
};

GlobalLanguageSelection.propTypes = {
  isPreview: PropTypes.bool
};

GlobalLanguageSelection.defaultProps = {
  isPreview: false
};

export default GlobalLanguageSelection;
