/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import styles from './ModuleMultiColumnCourses.module.scss';
import useCourseImage from '../../utils/useCourseImage';
import CourseCard from '../CourseCard';
import ElementLink, { ElementLinkPropTypes } from '../ElementLink';
import CategoriesPropTypes from '../Categories/CategoriesPropTypes';

export const ModuleMultiColumnCoursesPropTypes = {
  _id: PropTypes.string.isRequired,
  _contentTypeId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
  viewMoreCta: PropTypes.shape(ElementLinkPropTypes),
  className: PropTypes.string,
  theme: PropTypes.shape(CategoriesPropTypes),
  themeClassName: PropTypes.string
};

function SubCourseCard({ _id, course, cardFieldClassNames }) {
  const { t } = useTranslation();
  const { courseImage } = useCourseImage(_id, course.image, course.category);
  const cardCtaText =
    course.cardCtaText ||
    (course._contentTypeId === 'pageCourse' ? t('common:ctaTextSelfGuided') : t('common:ctaTextVirtual'));
  return (
    <div className={cx(styles.courseCol)} key={`${course._id}-col`}>
      <CourseCard
        _id={course._id}
        _contentTypeId={course._contentTypeId}
        headerText={course.cardTitle || course.name}
        image={courseImage}
        summaryText={course.cardDescription || course.description}
        cta={{
          href: course._href,
          as: course._as,
          linkText: cardCtaText,
          target: null,
          icon: null,
          isModal: false,
          download: false
        }}
        topics={course.topics}
        fieldClassNames={cardFieldClassNames}
      />
    </div>
  );
}

SubCourseCard.propTypes = {
  _id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  course: PropTypes.object.isRequired,
  cardFieldClassNames: PropTypes.shape({
    cardTitle: PropTypes.string.isRequired
  }).isRequired
};

function ModuleMultiColumnCourses({
  _id /* , _contentTypeId */,
  title,
  subtitle,
  courses,
  viewMoreCta,
  className,
  themeClassName
}) {
  // Checks card header text lengths to see if any will wrap past
  const cardFieldClassNames = {};
  const longestCourseNameLength = Math.max(...courses.map((c) => get(c, 'name', '').length));

  const charsPerLine = 9;
  if (longestCourseNameLength > charsPerLine * 3) cardFieldClassNames.cardTitle = 'multiLineThree';
  else if (longestCourseNameLength > charsPerLine * 2) cardFieldClassNames.cardTitle = 'multiLineTwo';
  else if (longestCourseNameLength > charsPerLine) cardFieldClassNames.cardTitle = 'multiLineOne';
  else cardFieldClassNames.cardTitle = 'singleLine';

  return (
    <section
      data-testid="ModuleMultiColumnCourses"
      className={cx(className, styles[themeClassName], styles.moduleContainerWrap)}>
      <div className={styles.sectionHeaderWrap}>
        <div className={styles.sectionTitleWrap} {...sidekick(_id, 'title')}>
          <h2 data-testid="ModuleMultiColumnCourses-title" className={styles.sectionTitle}>
            {title}
          </h2>
        </div>

        {subtitle ? (
          <div className={styles.subtitleWrap} {...sidekick(_id, 'subtitle')}>
            <p data-testid="ModuleMultiColumnCourses-subtitle" className={styles.subtitle}>
              {subtitle}
            </p>
          </div>
        ) : null}
      </div>

      <div
        data-testid="ModuleMultiColumnCourses-CoursesWrap"
        className={cx(styles.moduleContainerRow, viewMoreCta ? null : styles.showAll)}>
        {courses && courses.length
          ? courses.map((course) => (
              <SubCourseCard _id={_id} course={course} cardFieldClassNames={cardFieldClassNames} key={course._id} />
            ))
          : null}
      </div>

      {viewMoreCta ? (
        <div data-testid="ModuleMultiColumnCourses-ViewMoreLinkWrap" className={styles.viewMoreTextLinkWrap}>
          <ElementLink {...viewMoreCta} className={styles.viewMoreTextLink} />
        </div>
      ) : null}
    </section>
  );
}

ModuleMultiColumnCourses.propTypes = ModuleMultiColumnCoursesPropTypes;

ModuleMultiColumnCourses.defaultProps = {
  subtitle: null,
  viewMoreCta: null,
  className: null,
  themeClassName: null,
  theme: null
};

export default ModuleMultiColumnCourses;
