/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import sidekick from '@last-rev/contentful-sidekick-util';
import PropTypes from 'prop-types';
import find from 'lodash/filter';
import head from 'lodash/head';
import useTranslation from 'next-translate/useTranslation';
import { connect } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import cx from 'clsx';
import Router from 'next/router';
import ElementLink, { ElementLinkPropTypes } from '../ElementLink';
import styles from './GlobalHeader.module.scss';
import { loadUserProfile } from '../../redux/modules/user';
import Asset from '../Asset';
import AssetPropTypes from '../Asset/AssetPropTypes';
import SearchInput from '../SearchInput';
import useLoginOrLogoutUrl from '../../utils/useLoginOrLogoutUrl';

export const GlobalHeaderPropTypes = {
  topNavigationLogo: PropTypes.shape(AssetPropTypes).isRequired,
  mainNavigation: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)).isRequired,
  secondaryNavigation: PropTypes.arrayOf(PropTypes.shape(ElementLinkPropTypes)).isRequired
};

const mapStateToProps = (state) => {
  const {
    user: { firstName, lastInitial }
  } = state;
  return {
    firstName,
    lastInitial
  };
};

const mapDispatchToProps = {
  loadUser: loadUserProfile
};

function GlobalHeader({
  _id,
  // _contentTypeId,
  topNavigationLogo,
  mainNavigation,
  secondaryNavigation,
  firstName,
  lastInitial,
  loadUser
}) {
  const { t } = useTranslation();

  const signedIn = firstName !== null;

  const loginLogoutUrl = useLoginOrLogoutUrl(!signedIn);

  const menuRef = useRef(null);

  const handleClick = () => menuRef.current.click();

  useEffect(() => {
    if (!firstName) {
      loadUser();
    }
    return function cleanup() {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName]);

  const homeLink = head(find(mainNavigation, (link) => link.as === '/home'));

  return (
    <header data-testid="GlobalHeader" {...sidekick(_id, null, null, 'Global Header')} className={styles.globalHeader}>
      <Navbar bg="white" expand="md" className={styles.navbar}>
        <ElementLink
          href="https://www.dropbox.com/"
          linkText="Homepage"
          className={styles.logo}
          isModal={false}
          download={false}>
          <Asset {...topNavigationLogo} imageWrapClassName={styles.logoImageWrap} isInlineSvg preload />
        </ElementLink>

        <div data-testid="GlobalHeader-searchWrap" className={styles.searchBarWrap}>
          <SearchInput
            placeholder={t('common:headerSearchInputPlaceholder')}
            size="sm"
            onSearch={(search) => {
              Router.push(`/search?q=${search}`);
            }}
          />
        </div>

        <div data-testid="GlobalHeader-user-info" className={`${styles.signInWrap || ''} ${styles[signedIn] || ''}`}>
          {signedIn ? (
            <NavDropdown title={`${firstName} ${lastInitial}`} bsPrefix={styles.userName}>
              <NavDropdown.Item>
                <ElementLink href="/profile" linkText={t('common:ctaTextProfile')}>
                  {t('common:ctaTextProfile')}
                </ElementLink>
              </NavDropdown.Item>
              <NavDropdown.Item href={loginLogoutUrl}>{t('common:ctaTextLogout')}</NavDropdown.Item>
            </NavDropdown>
          ) : (
            <a href={loginLogoutUrl} className={styles.btnSignin} data-testid="GlobalHeader-signin">
              {t('common:ctaTextSignIn')}
            </a>
          )}
        </div>

        <Navbar.Collapse id="main-nav" className={styles.mainNavCollapse}>
          <Nav className={styles.mainNavBar} {...sidekick(_id, null, null, 'Navigation')}>
            {homeLink ? (
              <div
                className={styles.secondaryNavItemMain}
                {...sidekick(_id, 'homeLink', null, 'Home Link')}
                role="button"
                tabIndex="0"
                onClick={handleClick}
                onKeyDown={handleClick}>
                <ElementLink {...homeLink} className={cx(styles.mainNavLink, styles.mainNavLinkTopLevel)}>
                  {homeLink.linkText}
                </ElementLink>
              </div>
            ) : null}

            {secondaryNavigation && secondaryNavigation.length
              ? secondaryNavigation.map((link) => (
                  <div
                    className={styles.secondaryNavItemMain}
                    key={`main-nav-link-${link._id || link.as || link.href}`}
                    role="button"
                    tabIndex="0"
                    onClick={handleClick}
                    onKeyDown={handleClick}>
                    <ElementLink {...link} className={styles.mainNavLink}>
                      {link.linkText}
                    </ElementLink>
                  </div>
                ))
              : null}

            {mainNavigation && mainNavigation.length
              ? mainNavigation.map((link) => {
                  const linkClass = link.as === '/home' ? styles.mainNavLinkHome : null;
                  const liClass = link.as === '/home' ? styles.mainNavLiHome : null;
                  return (
                    <div
                      className={cx(styles.mainNavItem, liClass)}
                      key={`main-nav-link-${link._id || link.as || link.href}`}>
                      <ElementLink {...link} className={cx(styles.mainNavLink, styles.mainNavLinkTopLevel, linkClass)}>
                        {link.linkText}
                      </ElementLink>
                    </div>
                  );
                })
              : null}
          </Nav>
        </Navbar.Collapse>

        <Navbar.Toggle aria-controls="main-nav" className={styles.mainNavToggle} ref={menuRef}>
          <Asset
            url="/images/assets/icon-nav-bar-lines.svg"
            title="Open Mobile Nav"
            isInlineSvg
            imageWrapClassName="main-nav-toggle-open"
          />
          <Asset
            url="/images/assets/icon-close-x.svg"
            title="Close Mobile Nav"
            isInlineSvg
            imageWrapClassName="main-nav-toggle-close"
          />
        </Navbar.Toggle>
      </Navbar>

      {secondaryNavigation && secondaryNavigation.length ? (
        <div
          className={styles.secondaryNavbarWrap}
          {...sidekick(_id, 'secondaryNavigation', null, 'Secondary Navigation')}>
          <nav className={styles.secondaryNavbar}>
            <ul className={styles.secondaryNavbarNav}>
              {secondaryNavigation.map((link) => (
                <li
                  className={`secondary-nav-item ${styles.secondaryNavItem}`}
                  key={`secondary-nav-link-${link._id || link.as || link.href}`}>
                  <ElementLink {...link} className={styles.secondaryNavLink}>
                    {link.linkText}
                  </ElementLink>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      ) : null}
    </header>
  );
}

GlobalHeader.propTypes = {
  ...GlobalHeaderPropTypes,
  firstName: PropTypes.string,
  lastInitial: PropTypes.string,
  loadUser: PropTypes.func.isRequired
};

GlobalHeader.defaultProps = {
  firstName: null,
  lastInitial: 'null'
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalHeader);
